<template>
  <div class="template-sms">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="getAllTemplateSms && getAllTemplateSms.length"
      >
        <v-list-item
          class="list-ensemble-item"
          v-for="(smsTemplate, index) in getAllTemplateSms"
          :key="smsTemplate.id"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <v-list-item-content>
            <v-list-item-title class="flex center">
              <div class="font-text font-sz-14 bold-700">
                {{ smsTemplate.title }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('view')"
              @click.prevent.stop="handleShowClick(smsTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="eye" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(smsTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action class="ml-0">
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(smsTemplate)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{
            $i18n.locale === 'fr' ? 'Aucune Template SMS' : 'No Template SMS'
          }}
        </div>
      </div>
    </div>
    <!-- delete sms template -->
    <v-dialog v-model="deleteSmsTemplate" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer une Template SMS'
                : 'Delete SMS Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteSmsTemplate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{ $t('msgdeleted', { msg: 'template' }) }}
            <strong>
              {{ TemplateSmsToDelete ? TemplateSmsToDelete.title : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSmsTemplateLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateSmsError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateSmsError)">
                <li v-for="(e, index) in getTemplateSmsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateSmsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerTemplateSms"
            :loading="getSmsTemplateLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteSmsTemplate')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- update sms template  -->
    <v-dialog
      v-model="showTemplateSmsToUpdate"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Modifier une Template SMS'
                : 'Edit SMS Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModalTemplete('showTemplateSmsToUpdate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            ref="showTemplateSmsToUpdate"
            class="mt-2"
            v-if="TemplateSmsToUpdate"
          >
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('title')"
                  v-model="TemplateSmsToUpdate.title"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('title') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>

                <v-textarea
                  dense
                  :label="$t('message')"
                  v-model="TemplateSmsToUpdate.message"
                  :persistent-placeholder="true"
                  rows="6"
                  outlined
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('message') + ' ' + $t('msgOblg')]"
                ></v-textarea>
              </v-col>
              <v-col>
                <div class="mb-1 title-fields">
                  {{ $i18n.locale === 'fr' ? 'Champs' : 'Fields' }}
                  <div
                    class="font-text font-sz-12 ml-3 color-crm"
                    v-if="copierText"
                  >
                    {{ $i18n.locale === 'fr' ? 'Text copier' : 'Text copy' }}
                  </div>
                </div>
                <div
                  v-if="getSmsTemplatesFields && getSmsTemplatesFields.length"
                >
                  <v-text-field
                    v-for="(prop, val) of getSmsTemplatesFields"
                    :key="prop.id"
                    :value="val"
                    dense
                    disabled
                    outlined
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    class="input-fields-copy"
                  >
                    <template #append>
                      <v-icon
                        class="color-crm"
                        :title="$t('copy')"
                        @click.prevent.stop="
                          CopyFields({ prop: prop, val: val })
                        "
                        >mdi-content-copy</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
                <div v-else class="div-aucun">
                  <div class="titre">
                    {{ $i18n.locale === 'fr' ? 'Aucun Champs' : 'No Fields' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSmsTemplateLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateSmsError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateSmsError)">
                <li v-for="(e, index) in getTemplateSmsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateSmsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleUpdateSmsTemplate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn
            text
            @click="hideModalTemplete('showTemplateSmsToUpdate')"
            small
            >{{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--show template -->
    <v-dialog
      v-model="showSmsTemplateModal"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header text-capitalize">
            Template SMS :
            {{
              smsTemplateToShow && smsTemplateToShow.title
                ? smsTemplateToShow.title
                : ''
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('showSmsTemplateModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div v-if="smsTemplateToShow" v-html="getSmsToShow"></div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSmsTemplateLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateSmsError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateSmsError)">
                <li v-for="(e, index) in getTemplateSmsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateSmsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog('showSmsTemplateModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      TemplateSmsToDelete: null,
      TemplateSmsToUpdate: null,
      showTemplateSmsToUpdate: false,
      copierText: false,
      open: false,
      smsTemplateToShow: null,
      initLoading: false,
      deleteSmsTemplate: false,
      showSmsTemplateModal: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'fetchAllSmsTemplates',
      'resetErrorSmsTemplate',
      'deleteTemplateSms',
      'updateSmsTemplate'
    ]),
    resetModal() {
      this.TemplateSmsToDelete = null
      this.TemplateSmsToUpdate = null
      this.smsTemplateToShow = null
      this.resetErrorSmsTemplate()
    },
    closeDialog(ref) {
      this[ref] = false
      this.resetModal()
    },
    hideModalTemplete(ref) {
      this[ref] = false
      if (ref == 'showTemplateSmsToUpdate') {
        this.$refs.showTemplateSmsToUpdate.resetValidation()
      }
      this.resetModal()
    },
    CopyFields(text) {
      // Create a temporary textarea element
      const copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.opacity = '0'
      copyTextarea.value = text

      // Append the textarea to the document
      document.body.appendChild(copyTextarea)

      // Select the text in the textarea
      copyTextarea.select()

      // Use the Clipboard API to copy the selected text
      if (navigator.clipboard) {
        this.copierText = true
        navigator.clipboard
          .writeText(text)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            // Remove the temporary textarea
            document.body.removeChild(copyTextarea)
            setTimeout(() => {
              this.copierText = false
            }, 800)
          })
      } else {
        // Remove the temporary textarea
        document.body.removeChild(copyTextarea)
      }
    },
    handleDeleteClick(template) {
      this.TemplateSmsToDelete = template
      this.deleteSmsTemplate = true
    },
    async supprimerTemplateSms() {
      const response = await this.deleteTemplateSms({
        template: this.TemplateSmsToDelete
      })
      if (response) {
        this.closeDialog('deleteSmsTemplate')
      }
    },
    handleUpdateClick(template) {
      this.TemplateSmsToUpdate = { ...template }
      this.showTemplateSmsToUpdate = true
    },
    async handleUpdateSmsTemplate() {
      if (this.$refs.showTemplateSmsToUpdate.validate()) {
        this.loading = true
        const specific_field = []
        if (this.TemplateSmsToUpdate.message != null) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(
            this.getSmsTemplatesFields
          )) {
            if (this.TemplateSmsToUpdate.message.indexOf(value) !== -1) {
              specific_field.push(value)
            }
          }
        }
        this.TemplateSmsToUpdate.specific_field = specific_field
        const response = await this.updateSmsTemplate({
          TemplateSmsToUpdate: this.TemplateSmsToUpdate
        })
        if (response) {
          this.hideModalTemplete('showTemplateSmsToUpdate')
        }
        this.loading = false
      }
    },
    handleShowClick(smsTemplate) {
      //this.open = true
      this.smsTemplateToShow = smsTemplate
      this.showSmsTemplateModal = true
    }
  },
  computed: {
    ...mapGetters([
      'getSmsTemplateLoading',
      'getAllTemplateSms',
      'getTemplateSmsError',
      'getSmsTemplatesFields'
    ]),
    getSmsToShow() {
      return this.smsTemplateToShow.message.replaceAll('\n', '</br>')
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchAllSmsTemplates()
    this.initLoading = false
  }
}
</script>

<style scoped lang="scss">
.template-sms {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 254px) !important;
    height: calc(100vh - 254px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>
<style lang="scss">
.template-sms {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
